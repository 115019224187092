import React, { useCallback, useMemo, useState, useEffect, lazy, Suspense } from "react";
import { graphql, navigate } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Link } from "../components/link";

import BoxFilter from "../components/box-filter";
import BoxProgramma from "../components/box-programma";
import BoxLocation from "../components/box-location";
import BannerTickets from "../components/banner-tickets";
import BoxIniziativa from "../components/box-iniziativa";
import BoxSponsor from "../components/box-sponsor";
import BoxHelp from "../components/box-help";
import BoxNewsletter from "../components/box-newsletter";

const Spline = lazy(() => import("@splinetool/react-spline"));

const ALL_CATEGORY = "all";

const Programma = ({ data: { program }, location }) => {
  const [currentCategory, setCurrentCategory] = useState(ALL_CATEGORY);

  const setQueryCategory = useCallback(
    (filter) => {
      const params = new URLSearchParams(location.search);
      if (filter === ALL_CATEGORY) {
        params.delete("category");
        // } else if (!params.has("day") && filter === "workshop") {
        //   params.set("category", filter);
        //   params.set("day", "2");
      } else {
        params.set("category", filter);
      }
      const qs = params.toString();
      const to = (qs ? `?${qs}` : "") + location.hash;
      navigate(to);
      setCurrentCategory(filter);
    },
    [location]
  );

  // const setQueryDay = useCallback(
  //   (day) => {
  //     const params = new URLSearchParams(location.search);
  //     if (day === 1) {
  //       params.delete("day");
  //     } else {
  //       params.set("day", day);
  //     }
  //     const qs = params.toString();
  //     const to = (qs ? `?${qs}` : "") + location.hash;
  //     navigate(to);
  //     setCurrentDay(day);
  //   },
  //   [location]
  // );

  const [currentDay, setCurrentDay] = useState(1);

  const allProgramma = useMemo(() => {
    return program.nodes.reduce((acc, node) => {
      return [...acc, ...node.events];
    }, []);
  }, [program]);

  const filters = useMemo(() => {
    const allFilters = allProgramma.reduce((res, programma) => {
      if (programma.eventType) {
        res.add(programma.eventType);
      }
      return res;
    }, new Set());
    return [ALL_CATEGORY].concat([...allFilters].sort((a, b) => (a < b ? -1 : 1)));
  }, [allProgramma]);

  useEffect(() => {
    const queryCategory = new URLSearchParams(location.search).get("category");
    const queryDay = new URLSearchParams(location.search).get("day");
    if (queryCategory !== ALL_CATEGORY && filters.includes(queryCategory)) {
      setCurrentCategory(queryCategory);
    } else if (!queryCategory) {
      setCurrentCategory(ALL_CATEGORY);
    }
    if (queryDay !== "1" && ["2"].includes(queryDay)) {
      setCurrentDay(parseInt(queryDay));
    } else if (!queryDay) {
      setCurrentDay(1);
    }
  }, [filters, location.search]);

  const filteredProgram = useMemo(
    () =>
      currentCategory === ALL_CATEGORY
        ? allProgramma
        : allProgramma.filter((programma) => programma.eventType === currentCategory),
    [currentCategory, allProgramma]
  );

  const dayProgram = useMemo(
    () => filteredProgram.filter((programma) => programma.day === currentDay),
    [currentDay, filteredProgram]
  );

  return (
    <Layout>
      <Seo
        title={`Programma`}
        description={`Scopri il programma completo dell'Intranet Italia Day 2025`}
      />

      <div className="box-intro">
        <div className="animation-intro-wrap">
          <video
            width="1440px"
            height="720px"
            autoPlay
            muted
            loop
            playsInline
            className="animation-intro"
          >
            <source src="/assets/programma.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="box-intro__text">
          <div className="container-md">
            <div className="row">
              <div className="col-12 text-center">
                <div className="box-intro__claim">
                  <p>Workshop, keynote, case study, networking.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="programma" className="section section--grey-dark">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <div className="text-center py-5">
                <h2>Stiamo preparando il programma del prossimo evento.</h2>
                <p>Torna a trovarci tra qualche mese.</p>
                <p>
                  Se non lo hai già fatto, puoi iscriverti alla nostra{" "}
                  <strong>
                    <Link to="/programma/#newsletter" title="Form iscrizione newsletter">
                      newsletter
                    </Link>
                  </strong>{" "}
                  per avere tutti gli aggiornamenti sull'evento.
                </p>
              </div>
            </div>
          </div>
          {/*
          <div className="row">
            <div className="col-12">
              <BoxFilter items={filters} filter={currentCategory} setFilter={setQueryCategory} />
            </div>
          </div>
          */}
          {/*
          <div className="row">
            <div className="col-12 text-center">
              <button
                type="button"
                className={`btn-tab${currentDay === 1 ? " btn-tab--active" : ""}`}
                onClick={() => setQueryDay(1)}
              >
                Giovedì <strong>19 giugno</strong>
              </button>
              <button
                type="button"
                className={`btn-tab${currentDay === 2 ? " btn-tab--active" : ""}`}
                onClick={() => setQueryDay(2)}
              >
                Venerdì <strong>20 giugno</strong>
              </button>
            </div>
          </div>
          */}
        </div>

        {/*
        <div className="box-program-wrap">
          {dayProgram.map((item) => (
            <div key={item.id}>
              <BoxProgramma data={item} />
            </div>
          ))}

          <div className="box-program__download">
            <div className="container-md">
              <div className="row">
                <div className="col-12 col-md-8 offset-md-2 d-flex justify-content-center">
                  <div>
                    <Link to="/programma/#programma" className="btn-link btn-link--up">
                      <span className="btn-link__circle"></span>
                      <span className="btn-link__text">
                        Torna all'inizio{" "}
                        <span className="d-block">
                          del <span className="bold">programma</span>
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Suspense>
            <Spline
              scene="https://prod.spline.design/sJ7qReTbsbsrwlVa/scene.splinecode"
              className="animation-circle"
            />
          </Suspense>
        </div>
        */}
      </section>

      <BoxLocation />

      <BannerTickets />

      <BoxIniziativa />

      <BoxSponsor />

      <BoxHelp />

      <BoxNewsletter />
    </Layout>
  );
};

export default Programma;

export const query = graphql`
  query {
    program: allContentfulProgramPage {
      nodes {
        events {
          id
          day
          eventTitle
          eventType
          typeDescription
          room
          time
          icon {
            title
          }
          eventDescription {
            childMarkdownRemark {
              html
            }
          }
          speaker {
            role
            fullName
            link
            bio {
              bio
            }
            image {
              gatsbyImageData(width: 175, layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
