import React, { useCallback, useMemo, useState, useEffect } from "react";
import { graphql, navigate } from "gatsby";
import { useForm } from "react-hook-form";
import Layout from "../components/layout";
import Seo from "../components/seo";
import BoxFilter from "../components/box-filter";
import BoxProgramma from "../components/box-programma";

const ALL_CATEGORY = "all";

const DownloadProgramma = ({ data: { program }, location }) => {
  const [currentCategory, setCurrentCategory] = useState(ALL_CATEGORY);

  const setQueryCategory = useCallback(
    (filter) => {
      const params = new URLSearchParams(location.search);
      if (filter === ALL_CATEGORY) {
        params.delete("category");
        // } else if (!params.has("day") && filter === "workshop") {
        //   params.set("category", filter);
        //   params.set("day", "2");
      } else {
        params.set("category", filter);
      }
      const qs = params.toString();
      const to = (qs ? `?${qs}` : "") + location.hash;
      navigate(to);
      setCurrentCategory(filter);
    },
    [location]
  );

  const setQueryDay = useCallback(
    (day) => {
      const params = new URLSearchParams(location.search);
      if (day === 1) {
        params.delete("day");
      } else {
        params.set("day", day);
      }
      const qs = params.toString();
      const to = (qs ? `?${qs}` : "") + location.hash;
      navigate(to);
      setCurrentDay(day);
    },
    [location]
  );

  const [currentDay, setCurrentDay] = useState(1);

  const {
    register,
    formState: { isSubmitting, errors },
    handleSubmit,
  } = useForm();

  const onSubmit = async (values) => {
    try {
      const response = await fetch("/api/download-links", {
        method: "POST",
        body: JSON.stringify({ code: values.code }),
      });
      if (!response.ok) {
        throw new Error(
          `Error calling /api/download-links: ${response.status} ${response.statusText}`
        );
      }
      const data = await response.json();
      console.log("data: ", data);
      setDownloads(data.downloads);
    } catch (err) {
      console.error("Downloads error: ", err);
    }
  };

  const [downloads, setDownloads] = useState();

  const allProgramma = useMemo(() => {
    return downloads
      ? program.nodes
          .reduce((acc, node) => {
            return [...acc, ...node.events];
          }, [])
          .filter((item) => !!downloads[item.contentful_id])
      : [];
  }, [program, downloads]);

  const filters = useMemo(() => {
    const allFilters = allProgramma.reduce((res, programma) => {
      if (programma.eventType) {
        res.add(programma.eventType);
      }
      return res;
    }, new Set());
    return [ALL_CATEGORY].concat([...allFilters].sort((a, b) => (a < b ? -1 : 1)));
  }, [allProgramma]);

  useEffect(() => {
    const queryCategory = new URLSearchParams(location.search).get("category");
    const queryDay = new URLSearchParams(location.search).get("day");
    if (queryCategory !== ALL_CATEGORY && filters.includes(queryCategory)) {
      setCurrentCategory(queryCategory);
    } else if (!queryCategory) {
      setCurrentCategory(ALL_CATEGORY);
    }
    if (queryDay !== "1" && ["2"].includes(queryDay)) {
      setCurrentDay(parseInt(queryDay));
    } else if (!queryDay) {
      setCurrentDay(1);
    }
  }, [filters, location.search]);

  const filteredProgram = useMemo(
    () =>
      currentCategory === ALL_CATEGORY
        ? allProgramma
        : allProgramma.filter((programma) => programma.eventType === currentCategory),
    [currentCategory, allProgramma]
  );

  const dayProgram = useMemo(
    () => filteredProgram.filter((programma) => programma.day === currentDay),
    [currentDay, filteredProgram]
  );

  return (
    <Layout>
      <Seo
        title={`Download Programma`}
        description={`Scarica le presentazioni degli interventi dell'Intranet Italia Day 2025`}
      />

      <div className="box-intro">
        <div className="animation-intro-wrap">
          <video
            width="1440px"
            height="720px"
            autoPlay
            muted
            loop
            playsInline
            className="animation-intro"
          >
            <source src="/assets/programma.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="box-intro__text">
          <div className="container-md">
            <div className="row">
              <div className="col-12 text-center">
                <div className="box-intro__claim">
                  <p>Workshop, keynote, case study, networking.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="programma" className="section section--grey-dark">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <div className="text-center pt-5">Scarica le presentazioni degli interventi</div>
            </div>
          </div>
          {downloads ? (
            <>
              <div className="row">
                <div className="col-12">
                  <BoxFilter
                    items={filters}
                    filter={currentCategory}
                    setFilter={setQueryCategory}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <button
                    type="button"
                    className={`btn-tab${currentDay === 1 ? " btn-tab--active" : ""}`}
                    onClick={() => setQueryDay(1)}
                  >
                    Giovedì <strong>19 giugno</strong>
                  </button>
                  {/* <button
                    type="button"
                    className={`btn-tab${currentDay === 2 ? " btn-tab--active" : ""}`}
                    onClick={() => setQueryDay(2)}
                  >
                    Venerdì <strong>20 giugno</strong>
                  </button> */}
                </div>
              </div>
            </>
          ) : (
            <div className="row">
              <div className="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                <div className="text-center pt-5 mb-4">Inserisci il codice di accesso</div>
                <form onSubmit={handleSubmit(onSubmit)} className="form">
                  <div className="form__group">
                    <div className="form__wrap-input-text">
                      <input
                        type="text"
                        className="form__input-text"
                        id="code"
                        {...register("code", {
                          required: {
                            value: true,
                            message: "Campo obbligatorio",
                          },
                        })}
                      />
                      <label className="form__label" htmlFor="code">
                        Codice
                      </label>
                    </div>
                    {errors.code ? (
                      <span className="d-block form__error">{errors.code.message}</span>
                    ) : null}
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn-link btn-link--submit"
                    >
                      <span className="btn-link__circle"></span>
                      <span className="btn-link__text">Invia</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
        {downloads && (
          <div className="box-program-wrap">
            {dayProgram.map((item) => (
              <div key={item.id}>
                <BoxProgramma data={item} links={downloads[item.contentful_id]} />
              </div>
            ))}
          </div>
        )}
      </section>
    </Layout>
  );
};

export default DownloadProgramma;

export const query = graphql`
  query {
    program: allContentfulProgramPage {
      nodes {
        events {
          id
          contentful_id
          day
          eventTitle
          eventType
          typeDescription
          room
          time
          icon {
            title
          }
          eventDescription {
            childMarkdownRemark {
              html
            }
          }
          speaker {
            role
            fullName
            link
            bio {
              bio
            }
            image {
              gatsbyImageData(width: 175, layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
