import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Slider from "react-slick";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "../components/link";

const settings = {
  arrows: true,
  dots: true,
  infinite: true,
  swipe: true,
  variableWidth: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const LocationImgSlider = ({ btnCta }) => {
  const { img1, img2, img3, img4, img5, img6 } = useStaticQuery(graphql`
    {
      img1: file(relativePath: { eq: "location/1-magna.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 540, layout: CONSTRAINED)
        }
      }
      img2: file(relativePath: { eq: "location/2-magna.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 540, layout: CONSTRAINED)
        }
      }
      img3: file(relativePath: { eq: "location/3-magna.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 540, layout: CONSTRAINED)
        }
      }
      img4: file(relativePath: { eq: "location/4-magna.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 540, layout: CONSTRAINED)
        }
      }
      img5: file(relativePath: { eq: "location/5-magna.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 540, layout: CONSTRAINED)
        }
      }
      img6: file(relativePath: { eq: "location/6-magna.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 540, layout: CONSTRAINED)
        }
      }
    }
  `);
  return (
    <>
      <div className="container-md">
        <div className="row">
          <div className="col-12">
            <Slider {...settings} className="slider-location-img">
              <div>
                <GatsbyImage
                  image={getImage(img1)}
                  alt="location img 1"
                  objectPosition="center center"
                  className=""
                />
              </div>
              <div>
                <GatsbyImage
                  image={getImage(img2)}
                  alt="location img 2"
                  objectPosition="center center"
                  className=""
                />
              </div>
              <div>
                <GatsbyImage
                  image={getImage(img3)}
                  alt="location img 3"
                  objectPosition="center center"
                  className=""
                />
              </div>
              <div>
                <GatsbyImage
                  image={getImage(img4)}
                  alt="location img 4"
                  objectPosition="center center"
                  className=""
                />
              </div>
              <div>
                <GatsbyImage
                  image={getImage(img5)}
                  alt="location img 5"
                  objectPosition="center center"
                  className=""
                />
              </div>
              <div>
                <GatsbyImage
                  image={getImage(img6)}
                  alt="location img 6"
                  objectPosition="center center"
                  className=""
                />
              </div>
            </Slider>
          </div>
        </div>
        {btnCta && (
          <div className="row pb-5">
            <div className="col-12 col-md-11 offset-md-1 pb-5">
              <Link to={btnCta} className="btn-link">
                <span className="btn-link__circle"></span>
                <span className="btn-link__text">
                  Vai all'<span className="d-block bold">archivio edizioni</span>
                </span>
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LocationImgSlider;
